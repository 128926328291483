import React from 'react';
import MainLayout from 'components/layouts/MainLayout'
import MainTitle from 'components/titles/MainTitle'
import MainPageTitle from 'components/titles/MainPageTitle';
import VennDiagram from "components/charts/VennDiagram"
import "./PurposeSectionMobileStyles.scss"
import purpose_venn_diagram from "images/purpose_venn_diagram.png"
import buildings from "images/buildings.png"
import health_insurance from "images/health-insurance.png"
import goal_flag from "images/goal-flag.png"
import medical_folder from "images/medical-folder.png"
import { useInView } from 'react-intersection-observer';


function PurposeSectionMobile() {

    return (
        <MainLayout>
            <div className="purpose-page-mobile">
                <MainPageTitle>Purpose</MainPageTitle>
                <div className="contents-container">
                    <div className="content-container content-container-1">
                        <div className="icon-container">
                            <div className="purpose-icon purpose-icon-animate">
                                <img src={buildings} />
                            </div>
                        </div>
                        <br />
                        <MainTitle>Why building the right building matters?</MainTitle>
                        <br />
                        <br />
                        <div className="text-box">
                            <span>
                                Sustainability, durability and longevity are meaningless if the building does not meet the business purpose of the user.Forsight digital is planning to add to what matters most.
                            </span>
                        </div>
                    </div>
                    <div className="content-container content-container-2">
                        <div className="icon-container">
                            <div className="purpose-icon">
                                <img src={health_insurance} />
                            </div>
                        </div>
                        <br />
                        <MainTitle>Plan <i>fast</i></MainTitle>
                        <br />
                        <br />
                        <div className="text-box">
                            <span>
                                We are, a PropTech company, specializing in data driven processes to find facility requirements before design begins using AI.
                                <br />
                                <br />
                                Our software, FastPlan, is a SaaS based offering for healthcare planners to determine requirements for their projects.
                                <br />
                                <br />
                                We’ve used the tool ourselves on over 14 million SF of healthcare space throughout the world and are now working to commercialize the offering for everyone.
                                <br />
                                <br />
                                Most people who use it, love it.
                            </span>
                        </div>
                    </div>
                    <div className="content-container content-container-3">
                        <div className="icon-container">
                            <div className="purpose-icon">
                                <img src={goal_flag} />
                            </div>
                        </div>
                        <br />
                        <MainTitle>Our goal</MainTitle>
                        <br />
                        <br />
                        <div className="text-box">
                            <span>
                                Our company was founded by a healthcare architect, an epidemiologist, and a technologist, marrying their strengths to find the lurking insight in data.
                                <br />
                                <br />
                                Forsight digital’s goal is to allow anyone involved in the building of complex facilities fast decision making, supported by publicly available data though an easy-to-use interface.

                            </span>
                        </div>
                    </div>
                <div className="content-container content-container-4">
                    <div className="icon-container">
                        <div className="purpose-icon">
                            <img src={medical_folder} />
                        </div>
                    </div>
                    <br />
                    <MainTitle>A unique approach</MainTitle>
                    <br />
                    <br />
                    <div className="text-box">
                        <span>
                            Our unique approach uses choreographed patient journeys tied to diagnoses that indicates resource requirements of building spaces, major equipment, staff, financial performance, and other operational considerations.
                            <br />
                            <br />
                            Forsight digital uses publicly available sources so you do not need to find the data.If you have the data, then our tool will give you a more relevant response to your query.
                        </span>
                    </div>
                </div>
                <div className="content-container content-container-5">
                    <MainTitle>How it works?</MainTitle>
                    <br />
                    <br />
                    <div className="brochure-venn-diagram-purpose">
                        <img src={purpose_venn_diagram} />
                    </div>
                
                </div>
            </div>
            <br /><br />
        </div>
        </MainLayout >
    )
}

export default PurposeSectionMobile;