import React from 'react';
import MainLayout from 'components/layouts/MainLayout'
import MainTitle from 'components/titles/MainTitle'
import MainPageTitle from 'components/titles/MainPageTitle';
import VennDiagram from "components/charts/VennDiagram"
import PurposeSectionMobile from "components/sections/PurposeSectionMobile"
import "styles/pages/PurposeStyles.scss"
import { StoreContext } from 'store';



function Purpose() {

    const store = React.useContext(StoreContext)

    return (
        store.isMobile? <PurposeSectionMobile/>
        :<MainLayout>
                <div className={store.isMobile ? 'purpose-page-mobile' : 'purpose-page'}>
                <div className="content-container-1 purpose-page-section-banded txt-size-m">
                    <MainPageTitle>Why building the right building matters?</MainPageTitle>
                    
                    <p > Healthcare facilities are difficult to determine their spatial needs and how they will perform clinically.<br/> Forsight determines healthcare facilities requirements quickly, so they perform the way you need now and in the age of digital health.
                    <br/>
                    <br/>         
                    Imagine a building as a piece of clothing that can be passed from generation to generation. This is why building the right building matters, so it can fit the needs of the world, as the world changes.</p>
                    <br /> 
                    <br /> 
                    <MainTitle>A new kind of company</MainTitle>
                    <p>We are, a PropTech company, specializing in data driven processes to find facility requirements before design begins using Artificial Intelligence. Our software, <i style={{ fontStyle: 'italic'}}>Forsight</i>  is a SaaS based offering for healthcare stakeholders to determine requirements for their projects. We've used the tool ourselves on over 14 million SF of healthcare space throughout the world. The tool is now accessible for healthcare project stakeholders to use it at their own pace and or direction. Most people who use it, love it.</p>

                   
                    <br />
                    <MainTitle>We are different</MainTitle>
                    <p>
                        Our company is founded by a healthcare architect, an epidemiologist, and a technologist, marrying their strengths to find disparate yet connected data that can yield insight to clinical and business operations. Forsight digital’s goal is to allow anyone involved in the planning of complex facilities fast decision making, supported by publicly available data through an easy-to-use interface.  Our unique approach uses choreographed patient journeys tied to diagnoses that indicates requirements of building spaces, major equipment, staff, financial performance, and other operational considerations.  Forsight digital uses publicly available sources so you do not need to find the data.  If you have the data, then our tool will give you a more relevant response to your query.
                    </p>
                </div>
                <br /><br />
                <MainTitle>How our data is connected:</MainTitle>
                <div className="brochure-venn-diagram">
                    <VennDiagram />
                </div>
                <MainTitle>Why now?</MainTitle>
                <p style={{ fontSize: '20px' }}>Healthcare is on the cusp of a major transformation with the help of digital health devices. Today digital health is a small component of the total care experience. This experience is expected to grow significantly, while our built healthcare facilities stay static. Forsight gives you the tool to quickly investigate your options.</p>
            </div>
        </MainLayout>
    )
}

export default Purpose;