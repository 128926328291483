import React from 'react';
import venn_room from "images/venn_room.png"
import venn_pop_pyramid from "images/venn_pop_pyramid.png"
import pancreatic_cancer from "images/pancreatic-cancer.png"
import CompanyLogo from 'components/logos/CompanyLogo'



import "./VennDiagramStyles.scss"


function VennDiagram() {
    return (
        <div className="brochure-venn-diagram">
            <span className="venn-graphic venn-graphic-l"><img src={venn_pop_pyramid} /></span>
            <span className="venn-line venn-line-l"></span>
            <div className="venn-diagram">
                <span className="venn-circle venn-circle-l"></span>
                <span className="venn-circle venn-circle-t"></span>
                <span className="venn-circle venn-circle-r"></span>
                <span className="venn-glue"></span>
                <span className="venn-glue-img" style={{textAlign: 'center'}}>
                    < CompanyLogo logoType="PRODUCT_ONLY" fontSize="35.5px"/>
                </span>
                <span className="venn-title venn-title-l">Demand</span>
                <span className="venn-title venn-title-t">Spaces</span>
                <span className="venn-title venn-title-r">Clinical</span>
            </div>
            <span className="venn-line venn-line-t"></span>
            <span className="venn-graphic venn-graphic-t"><img src={venn_room} /></span>
            
            <span className="venn-line venn-line-r"></span>
            <span className="venn-graphic venn-graphic-r"><img src={pancreatic_cancer} /></span>
        </div>
    )
}

export default VennDiagram;